import { Text as Paragraph } from '../../Typography';

interface ICategorySubCategoryTextProps {
  textAlign?: string;
  category?: string;
  subCategory?: string;
}

const CategorySubCategoryText = ({
  textAlign,
  category,
  subCategory,
}: ICategorySubCategoryTextProps) => {
  const breadcrumb = `text-darkgray tracking-xl text-opacity-75 text-sm mt-3.5 pb-2.5 leading-4 font-momentstext uppercase ${textAlign}`;

  return (
    <div className={breadcrumb}>
      {category && (
        <Paragraph tag="p" type="sm" className="inline-block">
          {category}
        </Paragraph>
      )}
      {!!(category && subCategory) && <span className="px-2">/</span>}
      {subCategory && (
        <Paragraph tag="p" type="sm" className="inline-block">
          {subCategory}
        </Paragraph>
      )}
    </div>
  );
};

export default CategorySubCategoryText;
