import { Theme } from '../../../styles/getThemeConfig';

export const getCardBgColor = ({
  theme,
  cardVariation,
  isOdd,
  customColor,
}: {
  theme?: Theme | undefined;
  cardVariation?: string;
  isOdd?: boolean;
  customColor?: string;
}) => {
  return (
    (theme &&
      (cardVariation === 'product'
        ? `var(--theme-color-emphasize, ${customColor ?? 'none'})`
        : cardVariation === 'articleImage'
          ? `var(--theme-color-secondary, ${customColor ?? 'none'})`
          : '#E1B87F')) ||
    (isOdd ? '#EFDBB2' : '#E1B87F')
  );
};
