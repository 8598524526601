import { CardType, CardVariation } from '../types';
import { clsx } from 'clsx';

const CurveSvg = ({
  cardVariation,
  cardType,
  cardColor,
  isOdd,
}: {
  cardVariation?: CardVariation;
  cardType?: CardType;
  cardColor?: boolean;
  isOdd?: boolean;
}) => {
  let bottomStyle = '';

  switch (cardVariation) {
    case 'product':
      bottomStyle =
        'bottom-[calc(100%+70px)] lg:bottom-[calc(100%+66px)]  xl:bottom-[calc(100%+66px)]';
      break;
    case 'object':
      bottomStyle = 'bottom-[calc(100%+73px)]';
      break;
    case 'recipe':
      bottomStyle = 'bottom-[calc(100%+12px)]';
      break;
    case 'white':
      bottomStyle = 'bottom-[calc(100%+12px)]';
      break;
    case 'WideSmall':
      bottomStyle = 'bottom-[calc(100%+12px)]';
      break;
    case 'content':
      bottomStyle = 'bottom-[calc(100%+12px)]';
      break;
    case 'article':
      bottomStyle = 'hidden';
      break;
    case 'articleImage':
      bottomStyle = 'bottom-[calc(100%+12px)]';
      break;
    case 'subcategory':
      bottomStyle =
        cardType === 'mediumSmall' ? 'bottom-[calc(100%+36px)]' : 'bottom-[calc(100%+12px)]';
      break;
    case 'tipsAndTricks':
      bottomStyle = 'bottom-[calc(100%+12px)]';
      break;
  }

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 624 28"
      className={clsx(
        cardVariation === 'product' &&
          `[&>path]:fill-none  group-hover:[&>path]:text-[color:var(--theme-color-accent)] group-hover:[&>path]:border-0 ${
            cardColor
              ? 'group-hover:[&>path]:fill-gray'
              : isOdd
                ? 'group-hover:[&>path]:fill-secondary'
                : 'group-hover:[&>path]:fill-cake'
          }`,
        {
          '[&>path]:fill-white': cardVariation === 'white',
          '[&>path]:fill-gray':
            cardColor && cardVariation !== 'product' && cardVariation !== 'white',
          '[&>path]:fill-cake':
            !cardColor && cardVariation !== 'product' && cardVariation !== 'white' && !isOdd,
          '[&>path]:fill-secondary':
            !cardColor && cardVariation !== 'product' && cardVariation !== 'white' && isOdd,
        },
        'absolute -left-[50px] w-[calc(100%+100px)]',
        bottomStyle,
      )}
    >
      <path
        stroke={
          cardVariation === 'product'
            ? 'var(--theme-color-accent, #BBC4BA)'
            : cardVariation === 'white'
              ? '#BBC4BA'
              : 'none'
        }
        d="M16 203C7.16344 203 0 195.837 0 187V21.351C0 21.351 143.433 -1.4836e-05 313.3 0C483.167 1.48361e-05 624 21.3511 624 21.3511V187C624 195.837 616.836 203 608 203H16Z"
      />
    </svg>
  );
};

export default CurveSvg;
